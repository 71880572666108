import { v4 as uuidv4 } from 'uuid';

const HTTP_REGEX = '^https?:';

function getUrlProtocol(url: string): string | undefined {
    const matches = url.match(new RegExp(/^\w+:/, 'gi'));
    if (!matches || !matches.length) return;
    return matches[0];
}

function matchRegexProtocol(url: string, regex: string): boolean {
    const protocol = getUrlProtocol(url);
    if (typeof protocol === 'undefined') return false;
    return new RegExp(regex).test(protocol);
}

export function isHttpUrl(url: string): boolean {
    return matchRegexProtocol(url, HTTP_REGEX);
}

export function isNullish(value: any) {
    return value === null || value === undefined;
}

export function isParticleDev(): boolean {
    return typeof window !== 'undefined' && window.__PARTICLE_DEVELOPMENT__ === true;
}

export function isSafariOrIOS(): boolean {
    const ua = navigator.userAgent;
    return (
        /Version\/([0-9\\._]+).*Mobile.*Safari.*/.test(ua) ||
        /Version\/([0-9\\._]+).*Safari/.test(ua) ||
        /iP(hone|od|ad)/.test(ua)
    );
}

export function getDeviceId(): string {
    const storeKey = 'pn_device_id';
    let deviceId = localStorage.getItem(storeKey);
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem(storeKey, deviceId);
    }
    return deviceId;
}

export function getVersion(): string {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const packages = require('../../package.json');
    return `web_${packages.version}`;
}

export function popupWindow(url: string, title: string, w: number, h: number): Window | null {
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    return open(
        url,
        title,
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            w +
            ', height=' +
            h +
            ', top=' +
            top +
            ', left=' +
            left
    );
}
