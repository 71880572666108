import { fromHex, toHex } from 'tron-format-address';

export function toHexAddress(base58Address: string): string {
    if (!base58Address || base58Address === '') {
        return '';
    }
    return toHex(base58Address);
}

export function toBase58Address(hexAddress: string): string {
    if (!hexAddress || hexAddress === '') {
        return '';
    }
    return fromHex(hexAddress);
}
