import { ActiveAction, BI } from '@particle-network/analytics';
import { Chain, chains } from '@particle-network/common';
import { Auth } from './auth';
import { AuthError, Config, Wallet, AuthThemeConfig, EVMProvider, SolanaWallet, OpenBuyOptions } from './types';
import { WalletEntryPlugin, WalletEntryPosition } from './components/walletEntry/index';
import { buyUrl, WalletConfig, walletUrl } from './utils/wallet-url';
import { getDeviceId, getVersion, isParticleDev } from './utils';
import { particleActive } from './utils/active';
import { controller } from './controller';

declare global {
    interface Window {
        particle?: ParticleNetwork;
        __PARTICLE_DEVELOPMENT__?: boolean;
        __PARTICLE_AUTH_LOCALHOST__?: string;
    }
}

export class ParticleNetwork {
    public readonly isParticleNetwork = true;
    public readonly auth: Auth;
    private readonly config: Config;

    particleProvider: EVMProvider;
    localProvider: EVMProvider;
    solanaWallet: SolanaWallet;

    [key: string]: any;

    public readonly bi: BI;

    private walletEntry?: WalletEntryPlugin;

    constructor(config?: Config) {
        if (!config) {
            //for solana wallet adapter
            config = {
                projectId: '34c6b829-5b89-44e8-90a9-6d982787b9c9',
                clientKey: 'c6Z44Ml4TQeNhctvwYgdSv6DBzfjf6t6CB0JDscR',
                appId: 'c1ad1496-5707-4db6-8a2b-3e9f7273d846',
                chainName: 'Solana',
                chainId: 101, //mainnet
                wallet: {
                    displayWalletEntry: true,
                    defaultWalletEntryPosition: WalletEntryPosition.BR,
                },
            };
        }
        if (
            (!config.chainName || typeof config.chainName === 'string') &&
            (!config.chainId || typeof config.chainId === 'number') &&
            typeof config.projectId === 'string' &&
            typeof config.clientKey === 'string' &&
            typeof config.appId === 'string'
        ) {
            if (config.chainName && config.chainId) {
                const chain = chains.getChainInfo({
                    id: config.chainId,
                    name: config.chainName,
                });
                if (!chain) {
                    throw AuthError.unsupportedChain();
                }
            } else {
                config.chainName = 'Ethereum';
                config.chainId = 1;
            }

            this.config = config;

            this.bi = new BI({
                sdk_api_domain: isParticleDev() ? 'https://api-debug.particle.network' : 'https://api.particle.network',
                device_id: getDeviceId(),
                sdk_version: getVersion(),
                project_config: {
                    project_uuid: config.projectId,
                    project_key: config.clientKey,
                    project_app_uuid: config.appId,
                },
                auth: {
                    username: isParticleDev() ? 'test_user' : 'PcJBtrqq69TDpQtY',
                    password: isParticleDev() ? 'test_pass' : 'oGjPXSgKP9QtmxWQUkrfrUvvmsG62t',
                },
            });

            this.auth = new Auth(this.config, this.bi);

            // create Wallet Entry
            this.walletEntryCreate();

            if (typeof window !== 'undefined') {
                this.openActive();
                window.particle = this;
            }
        } else {
            throw AuthError.paramsError();
        }
    }

    private openActive() {
        if (this.auth.isLogin() && this.auth.wallet()?.public_address) {
            particleActive(
                this.bi,
                this.auth.chainId(),
                this.auth.wallet()!.public_address,
                this.auth.userInfo()!,
                ActiveAction.OPEN
            );
        }
    }

    public setAuthTheme(config: AuthThemeConfig) {
        this.auth.setAuthTheme(config);
        this.walletEntry?.setTheme?.(config);
    }

    public getAuthTheme() {
        return this.auth.getAuthTheme();
    }

    public async switchChain(chain: Chain, hideLoading = false): Promise<Wallet[]> {
        return this.auth.switchChain(chain, hideLoading);
    }

    /**
     * @deprecated this method will be removed in v1.0
     * @see switchChain
     */
    public setChainInfo(chain: Chain, hideLoading = false): Promise<Wallet[]> {
        return this.auth.switchChain(chain, hideLoading);
    }

    // wallet destroy
    public walletEntryDestroy() {
        this.walletEntry?.destroy();
        this.walletEntry = undefined;
    }

    // create wallet entry
    public walletEntryCreate() {
        if (!this.config?.wallet?.displayWalletEntry === undefined || this.config?.wallet?.displayWalletEntry) {
            this.walletEntry = new WalletEntryPlugin(this.auth, {
                position: this.config.wallet?.defaultWalletEntryPosition || WalletEntryPosition.BR,
                preload: this.config.wallet?.preload,
            });
        }
    }

    /**
     * open wallet web wallet
     * @param target window open target
     * @param features window open features
     */
    public openWallet(target?: string, features?: string) {
        const url = walletUrl(this.auth);
        if (typeof window !== 'undefined') {
            if (this.auth.isLogin() && this.auth.wallet()?.public_address) {
                particleActive(
                    this.bi,
                    this.auth.chainId(),
                    this.auth.wallet()!.public_address,
                    this.auth.userInfo()!,
                    ActiveAction.OPEN_WALLET
                );
            }
            window.open(url, target, features);
        }
    }

    public buildWalletUrl(config?: WalletConfig) {
        const url = walletUrl(this.auth, config);
        return url;
    }

    public openBuy(options?: OpenBuyOptions, target?: string, features?: string) {
        const url = buyUrl(this.auth, options);
        if (typeof window !== 'undefined') {
            window.open(url, target, features);
        }
    }

    public setLanguage(code: string) {
        controller.setLanguage(code);
    }

    public getLanguage(): string {
        return controller.getLanguage();
    }
}
