import { EventEmitter } from 'events';
import {
    Config,
    AuthError,
    UserInfo,
    Wallet,
    UIMode,
    AuthThemeConfig,
    LoginOptions,
    AuthType,
    UserSimpleInfo,
} from './types';
import { getDeviceId, getVersion, isParticleDev, isSafariOrIOS, popupWindow } from './utils';
import { Chain, ChainName, chains } from '@particle-network/common';
import { approvePopupRender } from './components/approvePopup';
import createSession from './utils/create-session';
import { ActiveAction, BI } from '@particle-network/analytics';
import { particleActive } from './utils/active';
import { controller } from './controller';
import { urlCrypto } from '@particle-network/crypto';
import { WalletEntryPlugin } from './components/walletEntry';
import silenceLogout from './utils/silence-logout';
import userSimpleInfo from './utils/user-simple-info';

interface SignOutput {
    signature?: string;
}

interface AuthResult {
    resolve: (value: any) => void;
    reject: (reason?: unknown) => void;
    container?: HTMLIFrameElement | Window | null;
}

type PrefixedHexString = string;

type Base58String = string;

export class Auth {
    private PN_AUTH_USER_INFO = 'pn_auth_user_info';

    private PN_TEMP_SECRET_KEY = 'pn_temp_secret_key';

    public events = new EventEmitter();

    private _authResult: AuthResult | null = null;

    private uiMode: UIMode = 'auto';

    private displayCloseButton = true;

    private displayWallet = false;

    private intervalTimer: NodeJS.Timer | undefined;

    constructor(readonly config: Config, private bi: BI) {
        addEventListener('message', (event) => {
            if (event?.data?.name === 'particle-network-provider') {
                this.handleAuthEvent(event);
            } else if (event?.data?.name === 'particle-network-wallet') {
                this.handleWalletEvent(event);
            }
        });
    }

    private handleAuthEvent(event: any) {
        if (!this._authResult) {
            return;
        }
        let data;
        try {
            data = this.decrypt(event.data.data);
        } catch (error: any) {
            data = {
                error: AuthError.decrypt(error),
            };
        }

        if (data.token && data.uuid) {
            this.setUserInfo(data as UserInfo);
        }

        if (data.wallets) {
            const userInfo = this.userInfo();
            if (userInfo) {
                userInfo.wallets = data.wallets;
                this.setUserInfo(userInfo);
            }
        }

        console.log('auth receive event message', data?.redirect_type);
        const { resolve, reject, container } = this._authResult;
        if (this.intervalTimer) {
            console.log('event: clear interval');
            clearInterval(this.intervalTimer);
            this.intervalTimer = undefined;
        }
        this._authResult = null;
        if (data.error) {
            if (data.error.code === 8005 || data.error.code === 10005) {
                this.setUserInfo(null);
                this.events.emit('disconnect');
            }
            reject(data.error);
        } else {
            resolve(data);
        }

        if (container) {
            try {
                if ('remove' in container) {
                    container.remove();
                }
            } catch (e) {
                //ignore
            }
        }

        const containerDiv = document.getElementById('particle-network-container');
        if (containerDiv) {
            containerDiv.style.display = 'none';
        }
    }

    private handleWalletEvent(event: any) {
        console.log('handleWalletEvent', event);
        const type = event?.data?.data?.type;
        if (type === 'logout') {
            this.setUserInfo(null);
            this.events.emit('disconnect');
        }
    }

    public async login(config?: LoginOptions): Promise<UserInfo> {
        let container: HTMLIFrameElement | Window | null = null;
        const url = await this.buildUrl('/login', {
            login_type: config?.preferredAuthType,
            support_auth_types: config?.supportAuthTypes ?? 'all',
            account: config?.account,
            login_form_mode: config?.loginFormMode,
            prompt: config?.socialLoginPrompt,
        });

        if (
            config &&
            config.preferredAuthType &&
            (this.isSocialLogin(config.preferredAuthType) || (config.account && isSafariOrIOS()))
        ) {
            if (this.isSocialLogin(config.preferredAuthType)) {
                container = await this.openUrl(url, 1000, 800, true, 'login');
            } else {
                container = await this.openUrl(url, 500, 750, true, 'login');
            }
        } else {
            container = this.getIframe();
            container.src = url;
            if (config?.preferredAuthType === 'jwt' && config?.hideLoading) {
                this.hideLoading(container);
            }
        }

        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve: (value: UserInfo) => {
                    this.events.emit('connect', value);
                    particleActive(
                        this.bi,
                        this.chainId(),
                        this.wallet()?.public_address || '',
                        this.userInfo()!,
                        ActiveAction.LOGIN
                    );
                    resolve(value);
                },
                reject,
                container: container,
            });
        });
    }

    private isSocialLogin(authType: AuthType): boolean {
        return authType !== 'email' && authType !== 'phone' && authType !== 'jwt';
    }

    public async logout(hideLoading = true): Promise<void> {
        if (!this.isLogin()) {
            return;
        }
        if (hideLoading) {
            try {
                await silenceLogout({
                    token: this.userInfo()?.token || '',
                    projectUuid: this.config.projectId,
                    projectKey: this.config.clientKey,
                });
            } catch (error: any) {
                if (error?.error_code !== 10005) {
                    throw error;
                }
            }

            this.setUserInfo(null);
            this.events.emit('disconnect');
        } else {
            const iframe = await this.openUrl(await this.buildUrl('/logout'));

            if (hideLoading) {
                // logout hidden iframe
                this.hideLoading(iframe);
            }

            return new Promise((resolve) => {
                this.setAuthResult({
                    resolve: () => {
                        this.setUserInfo(null);
                        this.events.emit('disconnect');
                        resolve();
                    },
                    reject: (error) => {
                        console.log('logout error', error);
                        this.setUserInfo(null);
                        this.events.emit('disconnect');
                        resolve();
                    },
                    container: iframe,
                });
            });
        }
    }

    public async accountSecurity(): Promise<void> {
        if (!this.isLogin()) {
            return Promise.reject(AuthError.notLogin());
        }
        const container = await this.openUrl(
            await this.buildUrl('/account/security', { token: this.userInfo()?.token })
        );
        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve,
                reject,
                container: container,
            });
        });
    }

    public async getUserSimpleInfo(): Promise<UserSimpleInfo> {
        if (!this.isLogin()) {
            return Promise.reject(AuthError.notLogin());
        }
        const { projectId, clientKey, appId } = this.config;
        const info = await userSimpleInfo({
            projectUuid: projectId,
            projectKey: clientKey,
            projectAppUuid: appId,
            token: this.userInfo()?.token || '',
        });
        const userInfo = this.userInfo();
        if (userInfo) {
            this.setUserInfo({ ...userInfo, ...info });
        }

        return info;
    }

    public async sign(method: string, message: Base58String | PrefixedHexString): Promise<string> {
        if (!this.walletExist()) {
            return Promise.reject(AuthError.walletNotCreated());
        }
        let container: HTMLIFrameElement | Window | null;
        if (this.config.chainName === 'Solana') {
            container = await this.openUrl(
                await this.buildUrl('/solana/sign', {
                    token: this.userInfo()?.token,
                    method: method,
                    chain_id: Number(this.config.chainId),
                    message: message,
                })
            );
        } else {
            container = await this.openUrl(
                await this.buildUrl('/evm-chain/sign', {
                    token: this.userInfo()?.token,
                    method: method,
                    chain_id: Number(this.config.chainId),
                    message: message,
                })
            );
        }

        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve: (value: SignOutput) => {
                    particleActive(
                        this.bi,
                        this.chainId(),
                        this.wallet()?.public_address || '',
                        this.userInfo()!,
                        ActiveAction.SIGN
                    );
                    resolve(value.signature ?? '');
                },
                reject,
                container: container,
            });
        });
    }

    public async signAllTransactions(messages: Base58String[]): Promise<string[]> {
        if (!this.walletExist()) {
            return Promise.reject(AuthError.walletNotCreated());
        }

        if (this.config.chainName !== 'Solana') {
            return Promise.reject(AuthError.unsupportedMethod());
        }

        const result = await this.sign('signAllTransactions', JSON.stringify(messages));
        const signatures: string[] = JSON.parse(result);
        return signatures;
    }

    public async sendTransaction(message: Base58String | PrefixedHexString): Promise<string> {
        if (!this.walletExist()) {
            return Promise.reject(AuthError.walletNotCreated());
        }

        if (this.config.chainName === 'Solana') {
            return this.sign('signAndSendTransaction', message);
        } else {
            return this.sign('eth_sendTransaction', message);
        }
    }

    public async switchChain(chain: Chain, hideLoading = false): Promise<Wallet[]> {
        const userInfo = this.userInfo();
        if (!userInfo) {
            return Promise.reject(AuthError.notLogin());
        }

        if (typeof chain.name !== 'string' || typeof chain.id !== 'number') {
            throw AuthError.paramsError();
        }

        const chainInfo = chains.getChainInfo(chain);

        if (!chainInfo) {
            throw AuthError.unsupportedChain();
        }

        const wallets = userInfo.wallets;
        if (this.config.chainName === chain.name && this.config.chainId === chain.id) {
            return wallets;
        }

        const wallet = this.wallet(chain.name === 'Solana' ? 'solana' : 'evm_chain');
        if (wallet) {
            this.config.chainName = chain.name;
            this.config.chainId = chain.id;
            this.events.emit('chainChanged', chain);
            return wallets;
        }

        const result = await this.createWallet(chain.name, hideLoading);
        this.config.chainName = chain.name;
        this.config.chainId = chain.id;
        this.events.emit('connect', this.userInfo());
        this.events.emit('chainChanged', chain);

        return result;
    }

    /**
     * @deprecated this method will be removed in v1.0
     * @see switchChain
     */
    public async setChainInfo(chain: Chain, hideLoading = false): Promise<Wallet[]> {
        return await this.switchChain(chain, hideLoading);
    }

    //create wallet
    public async createWallet(name: ChainName, hideLoading = false): Promise<Wallet[]> {
        const userInfo = this.userInfo();
        if (!userInfo) {
            return Promise.reject(AuthError.notLogin());
        }

        const wallet = this.wallet(name === 'Solana' ? 'solana' : 'evm_chain');
        if (wallet) {
            return userInfo.wallets;
        }

        const container = await this.openUrl(
            await this.buildUrl('/wallet', {
                token: userInfo.token,
                chain_name: name,
            })
        );

        if (hideLoading) {
            this.hideLoading(container);
        }

        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve: (value: Wallet[]) => {
                    resolve(value);
                },
                reject,
                container: container,
            });
        });
    }

    private hideLoading(container: HTMLIFrameElement | Window | null) {
        const containerDiv = document.getElementById('particle-network-container');
        if (containerDiv) {
            containerDiv.style.display = 'none';
        }
        try {
            if (container && 'remove' in container) {
                container.style.display = 'none';
            }
        } catch (e) {
            //ignore
        }
    }

    public chainId(): number {
        return this.config.chainId!;
    }

    public chain(): Chain {
        return {
            id: this.config.chainId!,
            name: this.config.chainName!,
        };
    }

    public basicCredentials(): string {
        return `Basic ${Buffer.from(`${this.config.projectId}:${this.config.clientKey}`, 'utf8').toString('base64')}`;
    }

    public isLogin(): boolean {
        return this.userInfo() !== null;
    }

    public userInfo(): UserInfo | null {
        const info = localStorage.getItem(this.concatStorageKey(this.PN_AUTH_USER_INFO));
        return info ? JSON.parse(info) : null;
    }

    public walletExist(): boolean {
        return this.wallet() != null;
    }

    public wallet(chainType?: string): Wallet | null {
        const userInfo = this.userInfo();
        if (!userInfo) {
            return null;
        }
        const wallet = userInfo.wallets.find((wallet) => wallet.chain_name === (chainType || this.walletChainName()));
        if (wallet !== undefined && wallet.public_address.length > 0) {
            return wallet;
        }
        return null;
    }

    public setAuthTheme(config: AuthThemeConfig) {
        if (config.uiMode) {
            this.uiMode = config.uiMode;
        }
        if (config.displayCloseButton !== null && config.displayCloseButton !== undefined) {
            this.displayCloseButton = config.displayCloseButton;
        }
        if (config.displayWallet !== null && config.displayWallet !== undefined) {
            this.displayWallet = config.displayWallet;
        }
    }

    public getAuthTheme(): AuthThemeConfig {
        return {
            uiMode: this.uiMode,
            displayCloseButton: this.displayCloseButton,
            displayWallet: this.displayWallet,
        };
    }

    public url(): string {
        const productionAuthUrl = 'https://auth.particle.network';
        const devAuthUrl = 'https://auth-debug.particle.network';
        if (
            typeof window !== 'undefined' &&
            window.__PARTICLE_AUTH_LOCALHOST__ &&
            typeof window.__PARTICLE_AUTH_LOCALHOST__ === 'string' &&
            window.__PARTICLE_AUTH_LOCALHOST__.includes('localhost')
        ) {
            return window.__PARTICLE_AUTH_LOCALHOST__;
        }
        return isParticleDev() ? devAuthUrl : productionAuthUrl;
    }

    public on(event: string, listener: (...args: any[]) => void): void {
        this.events.on(event, listener);
    }

    public once(event: string, listener: (...args: any[]) => void): void {
        this.events.once(event, listener);
    }

    public off(event: string, listener: (...args: any[]) => void): void {
        this.events.off(event, listener);
    }

    public removeListener(event: string, listener: (...args: any[]) => void): void {
        this.events.removeListener(event, listener);
    }

    private walletChainName(): string {
        return this.config.chainName === 'Solana' ? 'solana' : 'evm_chain';
    }

    private setAuthResult(authResult: AuthResult) {
        if (this.intervalTimer) {
            console.log('setAuthResult: clear interval');
            clearInterval(this.intervalTimer);
            this.intervalTimer = undefined;
        }
        this._authResult = authResult;
        this.listenWindowClose(authResult);
    }

    private listenWindowClose(authResult: AuthResult) {
        if (authResult?.container) {
            try {
                if ('close' in authResult.container) {
                    console.log('add window close listener', authResult.container.closed);
                    this.intervalTimer = setInterval(() => {
                        if (authResult?.container && 'close' in authResult.container && authResult.container.closed) {
                            console.log('close: clear interval');
                            clearInterval(this.intervalTimer);
                            this.intervalTimer = undefined;
                            console.log(
                                'listen window closed, rejectOperation',
                                this._authResult === authResult,
                                this._authResult
                            );
                            if (authResult && this._authResult && authResult === this._authResult) {
                                this._authResult.reject(AuthError.userCancelOperation());
                                this._authResult = null;
                            }
                        }
                    }, 500);
                }
            } catch (e) {
                console.error('listen window close', e);
            }
        }
    }

    private setUserInfo(info: UserInfo | null) {
        if (info) {
            localStorage.setItem(this.concatStorageKey(this.PN_AUTH_USER_INFO), JSON.stringify(info));
        } else {
            localStorage.removeItem(this.concatStorageKey(this.PN_AUTH_USER_INFO));
            localStorage.removeItem(WalletEntryPlugin.WALLET_BTN_POSITION);
        }
    }

    private concatStorageKey(key: string): string {
        return `${key}_${this.config.appId}`;
    }

    private getIframe(): HTMLIFrameElement {
        let containerDiv = document.getElementById('particle-network-container');
        if (!containerDiv) {
            containerDiv = document.createElement('div');
            containerDiv.setAttribute(
                'style',
                'display: block;position: fixed;top: 0px;right: 0px;width: 100%;height: 100%;border-radius: 0px;border: none;z-index: 2147483647;background-color: rgba(0, 0, 0, 0.5);align-items: center;'
            );
            containerDiv.id = 'particle-network-container';
            document.body.appendChild(containerDiv);
        } else {
            containerDiv.style.display = 'block';
        }

        let iframe: HTMLIFrameElement;
        const elements = document.getElementsByName('particle-network-iframe');
        if (elements.length > 0) {
            iframe = elements[0] as HTMLIFrameElement;
            iframe.style.display = '';
        } else {
            iframe = document.createElement('iframe');
            iframe.name = 'particle-network-iframe';
            let bgColor = '#FFFFFF';
            const themeType = this.getThemeType();
            if (themeType === 'dark') {
                bgColor = '#000000';
            }
            const { width: screenWidth } = window.screen;
            let width = '400px';
            let height = '650px';
            let top = '50%';
            let left = '50%';
            let borderRadius = 10;
            let transform = 'translate(-50%, -50%)';

            if (screenWidth < 500) {
                width = '100%';
                height = '100%';
                borderRadius = 0;
                transform = 'none';
                top = '0px';
                left = '0px';
            }

            const iframeStyles = {
                position: 'absolute',
                left,
                top,
                transform,
                width,
                height,
                border: 'none',
                'border-radius': `${borderRadius}px`,
                'z-index': '2147483647',
                'box-shadow': '-1px 3px 11px 2px #00000073',
                'background-color': bgColor,
            };

            iframe.setAttribute(
                'style',
                Object.entries(iframeStyles)
                    .map(([key, value]) => `${key}:${value}`)
                    .join(';')
            );
            containerDiv.appendChild(iframe);
        }
        return iframe;
    }

    private async openUrl(
        url: string,
        width = 500,
        height = 750,
        forcePopup = false,
        contentKey: 'sign' | 'login' = 'sign'
    ): Promise<HTMLIFrameElement | Window | null> {
        if (isSafariOrIOS() || forcePopup) {
            let container = popupWindow(url, 'particle-auth', width, height);
            if (!container) {
                // window open blocked
                console.log('particle popup window blocked');
                container = await this.continuePopup(url, width, height, contentKey);
            }
            container.name = 'particle-auth-popup';
            return container;
        }

        const iframe = this.getIframe();
        iframe.src = url;
        return iframe;
    }

    private async continuePopup(
        url: string,
        width = 500,
        height = 750,
        contentKey: 'sign' | 'login' = 'sign'
    ): Promise<Window> {
        return new Promise<Window>((resolve, reject) => {
            approvePopupRender(() => {
                const popup = popupWindow(url, 'particle-auth', width, height);
                if (popup) {
                    resolve(popup);
                } else {
                    reject(new Error('popup window blocked'));
                }
            }, contentKey);
        });
    }

    private async buildUrl(path: string, extraParams: any = {}): Promise<string> {
        const params = {
            project_uuid: this.config.projectId,
            project_client_key: this.config.clientKey,
            project_app_uuid: this.config.appId,
            chain_name: this.config.chainName,
            sdk_version: getVersion(),
            device_id: getDeviceId(),
        };
        Object.assign(params, { ...extraParams });
        let value = urlCrypto.encryptUrlParam(params);
        const secretKey = value.slice(-32);
        sessionStorage.setItem(this.concatStorageKey(this.PN_TEMP_SECRET_KEY), secretKey);
        if (value.length > 10000) {
            const sessionKey = await createSession(value);
            value = `session_key_${sessionKey}`;
        }

        let url = `${this.url()}?params=${value}&encoding=base64&theme_type=${this.getThemeType()}&display_close_button=${
            this.displayCloseButton
        }&display_wallet=${this.displayWallet}&language=${controller.getLanguage()}`;
        if (this.config.securityAccount) {
            url += `&security_account=${encodeURIComponent(JSON.stringify(this.config.securityAccount))}`;
        }
        return `${url}#${path}`;
    }

    getThemeType(): string {
        return this.uiMode === 'auto'
            ? window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                ? 'dark'
                : 'light'
            : this.uiMode;
    }

    private decrypt(data: string): any {
        const secretKey = sessionStorage.getItem(this.concatStorageKey(this.PN_TEMP_SECRET_KEY)) || '';
        const plaintext = urlCrypto.decryptData(data, secretKey, 'hex');
        return JSON.parse(plaintext);
    }
}
